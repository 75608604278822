import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(landing)": [~24],
		"/(app)/explore": [~4],
		"/(app)/home": [~5],
		"/(landing)/job-list": [~25],
		"/(jobs)/jobs": [19],
		"/(jobs)/jobs/[company]": [~20],
		"/(jobs)/jobs/[company]/roles/[slug]": [~23],
		"/(jobs)/jobs/[company]/[roleId]": [~21],
		"/(jobs)/jobs/[company]/[roleId]/apply": [~22],
		"/(landing)/journey": [26],
		"/(learn)/learn": [~34],
		"/(app)/learn/enrollments": [~6],
		"/(app)/learn/workshops/[id]": [~7],
		"/(landing)/login": [~27],
		"/(auth)/login/success": [~16],
		"/(auth)/logout": [~17],
		"/(app)/onboarding/process": [~8],
		"/(app)/onboarding/professions": [9],
		"/(app)/onboarding/profile": [~10],
		"/(landing)/plans-invoice": [~29],
		"/(landing)/plans": [~28],
		"/(app)/post-payment": [11],
		"/(shared)/posts/[answerId]/edit": [~44],
		"/(shared)/posts/[user_name]/[slug]": [~45],
		"/(landing)/privacy": [30],
		"/(app)/questions/[id]": [~12],
		"/recuriter": [51],
		"/settings": [52],
		"/(app)/sf/[strength_token]": [~13],
		"/(storytelling-for-founders)/storytelling-for-founders": [49],
		"/(storytelling-for-founders)/storytelling-for-founders/pay": [~50],
		"/(storytelling)/storytelling": [46],
		"/(storytelling)/storytelling/pay": [~47],
		"/(storytelling)/storytelling/storytelling": [~48],
		"/(app)/stripe-cancel": [14],
		"/(landing)/terms": [31],
		"/(landing)/v2": [~32],
		"/(auth)/verify": [~18],
		"/(landing)/view": [33],
		"/(app)/vi/[vouch_token]": [~15],
		"/(shared)/[user_name]": [40,[3]],
		"/(shared)/[user_name]/about": [41,[3]],
		"/(shared)/[company_id]/dashboard": [~36,[2]],
		"/(shared)/[user_name]/interview-me": [42,[3]],
		"/(shared)/[company_id]/job-posting": [~37],
		"/(shared)/[company_id]/job-posting/create": [~38],
		"/(shared)/[company_id]/job-posting/edit/[roleId]": [~39],
		"/(organization)/[org]/roles/[slug]": [~35],
		"/(shared)/[user_name]/takes": [43,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';